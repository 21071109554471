import React, { createContext, useContext } from 'react';


const PageTransitionContext = createContext();

export const PageTransitionProvider = ({ children }) => {
    const pageTransition = [
        {
            opacity:0,
            scale:0.99,
        },
        {
            opacity:1,
            scale:1,
        },
        {
            ease:'easeInOut',
            duration:0.3
        }
    ]

  return (
    <PageTransitionContext.Provider value={pageTransition}>
      {children}
    </PageTransitionContext.Provider>
  );
};


export const usePageTransition = () => {
  return useContext(PageTransitionContext);
};
