import React, { Suspense,lazy, useState } from 'react'
import { Route,Routes, Outlet, useLocation } from 'react-router-dom'
import LoadTop from './LoadTop'
import Loading from './Loading.js'
import { AnimatePresence } from 'framer-motion'
import ErrorPage from '../pages/error/ErrorPage.js'
import Header from './header/Header.js'
import Footer from './footer/Footer.js'
import About from '../pages/ourCompany/About/About.js'
import ScrollToTopButton from './ScrollToTopButton.js'

const Home = lazy(() => import('../pages/home/Home.js'));
const ContactUs = lazy(()=>import('../pages/contactUs/ContactUs.js'));
const Products = lazy(()=>import('../pages/products/Products.js'));
const ProductCategoryLayout = lazy(()=>import('../pages/products/productCategory/ProductCategoryLayout.js'));
const ProductItemOrSubcategory = lazy(()=>import('../pages/products/productCategory/ProductItemOrSubcategory.js'));
const ProductCategory = lazy(()=>import('../pages/products/productCategory/ProductCategory.js'));
const Team = lazy(()=>import('../pages/ourCompany/Team/Team.js'));
const Partners = lazy(()=>import('../pages/ourCompany/Partners/Partners.js'))
const Testimonials = lazy(()=>import('../pages/ourCompany/Testimonials/Testimonials.js'));

// Memoized Header and Footer
const MemoizedHeader = React.memo(() => {
  return <Header />;
});

const MemoizedFooter = React.memo(() => {
  return <Footer />;
});


const MainLayout = React.memo((props) => {
  const [alert,setAlert] = useState(true);
  return (
    <>    
      <main className="page">
        <ScrollToTopButton/>
        {props.location.pathname==='/' && (
          <div className="home-grd" />
        )}        
        <LoadTop />
        <MemoizedHeader />
        <Suspense fallback={<Loading />}>
          <Outlet />
        </Suspense>
      </main>
      <MemoizedFooter />
    </>
  );
});

const Router = () => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location}>
        <Route element={<MainLayout location={location}/>}>
          <Route
            index
            element={
              <Suspense fallback={<Loading />}>
                <Home />
              </Suspense>
            }
          />
          <Route
            path="products"
            element={
              <Suspense fallback={<Loading />}>
                <Products />
              </Suspense>
            }
          />
          <Route
            path="/products/:category"
            element={
              <Suspense fallback={<Loading />}>
                <ProductCategoryLayout />
              </Suspense>
            }
          >
            <Route
            index
            element={
              <Suspense fallback={<Loading />}>
                <ProductCategory />
              </Suspense>
            }
          />
            
            <Route
              path=":subCategory"
              element={
                <Suspense fallback={<Loading />}>
                  <ProductItemOrSubcategory />
                </Suspense>
              }
            >
            <Route
              path=":item"
              element={
                <Suspense fallback={<Loading />}>
                  <ProductItemOrSubcategory />
                </Suspense>
              }
            />
            </Route>
          </Route>
          <Route
            path="our-company/about"
            element={
              <Suspense>
                <About/>
              </Suspense>
              }
            />
          <Route
            path="our-company/team"
            element={
              <Suspense fallback={<Loading />}>
                <Team />
              </Suspense>
            }
          />
          <Route
            path="our-company/partners"
            element={
              <Suspense fallback={<Loading />}>
                <Partners/>
              </Suspense>
            }
          />
          <Route
            path="our-company/testimonials"
            element={
              <Suspense>
                <Testimonials/>
              </Suspense>
              }
            />
          <Route
            path="contact-us"
            element={
              <Suspense fallback={<Loading />}>
                <ContactUs />
              </Suspense>
            }
          />
        </Route>
        <Route path="*" element={<ErrorPage/>}></Route>
      </Routes>
    </AnimatePresence>
  );
};

export default Router;