import React from 'react'
import './Footer.css';
import { Link } from 'react-router-dom';
import contactDetails from '../../data/contactDetails.json'
import {motion} from 'framer-motion'

const email = contactDetails.mailto;
const whatsapp = contactDetails.whatsapplink;

const Footer =  React.memo(() => {
    const footerItems = [
        {
            'title':'How the company operates',
            'items':['About','Partners','Team']
        },
        {
            'title':'Services',
            'items':['Healthcare','Fuel Additive','Detection Equipment','Air Sterilizer']
        },
        {
            'title':'Other Links',
            'items':['Contact Us']
        },
    ]
    const getlink = (name)=>{
        switch (name) {
            case "How the company operates":
                return '/our-company'
            case "Services":
                return '/products'
            case "Other Links":
                return ''
            default:
                return '/'
        }
    }
  return (
    <div className='ftr-main'>
        <div className="ftr-company-div">
            <img className='ftr-logo' src="/static/images/logo-white.svg" alt="logo"/>
            <div className="ftr-txt">
                <p>Core Green is a international trading company estd. in 2020, specializing in the sourcing and distribution of innovative, sustainable products worldwide. </p>
                <div>
                <Link to={whatsapp} target='_blank'>
                    <motion.button whileHover={{opacity:0.5,scale:1.03}}>
                        <img src="/static/images/whatsapp.svg" alt="whatsapp" />
                    </motion.button>
                </Link>
                <Link to={`mailto:${email}`}>
                    <motion.button whileHover={{opacity:0.5,scale:1.03}}>
                        <img src="/static/images/mail.svg" alt="mail"/>
                    </motion.button>
                </Link>
                </div>
            </div>  
        </div>
        <div className="ftr-site-div">
            <div className="ftr-site-lnk">
                {footerItems.map((item)=>{
                    return(
                        <div className='ftr-lnk-inner' key={`ftr-${item.title}`}>
                            <h4>{item.title}</h4>
                            <ul>
                                {item.items.map((subitem,index)=>
                                    <Link to={`${getlink(item.title)}/${subitem==="Detection Equipment"?"security-&-detection-equipment":subitem.toLowerCase().replaceAll(' ','-')}`}>
                                        <li key={`ftr-${subitem}`}>{subitem}</li>
                                    </Link>
                                )}
                            </ul>
                        </div>
                    )
                })}           
            </div>
            <div className="ftr-c">
                    <p>© 2024 Core Green LLC</p>
                    
            </div>  
        </div> 
           
    </div>
  )
})

export default Footer
