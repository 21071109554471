import React, { useRef } from 'react';
import './About.css'
import {motion, useInView} from 'framer-motion';
import { usePageTransition } from '../../../components/pageTransitionContext';
import SEO from '../../../components/SEO';
import Team from '../../../data/Team.json'

import ImageText from '../../../components/ImageText/ImageText.js'

const About = () => {
    const pageTransitions = usePageTransition();
    const visionRef = useRef(null);
    const teamRef = useRef(null);
    const visionInView = useInView(visionRef,{amount:0.5})
    const teamInView = useInView(teamRef,{amount:0.5});
    const imgtransition = {duration:0.4,delay:0.3,ease:'easeInOut'};
    const imginit = {opacity:0,y:-30};
    const imgfnl = {opacity:1,y:0};

  return (
    <motion.div initial={pageTransitions[0]} animate={pageTransitions[1]} exit={pageTransitions[0]} transition={pageTransitions[2]} className='page aboutpage' id="innerpage">
        <SEO
            title="About"
            description="Core Green is a global trading company committed to providing innovative, efficient, and eco-friendly solutions to a diverse range of industries."
        />
        <section className="abt-sc">
            <div className='abt-h'>
                <h2>ABOUT</h2>
            </div>
            <div className='abt-cmp'>
                <div className='abt-cm-i'>
                    <motion.img initial={imginit} animate={imgfnl} transition={imgtransition} src="/static/images/logo-text.svg" alt="Core Green Trading Company LLC" />
                </div>
                <p>
                    Core Green is a global trading company committed to providing innovative, efficient, and eco-friendly solutions to a diverse range of industries. 
                    With a strong focus on healthcare, security, and sustainability, 
                    we offer a comprehensive portfolio of products and services that cater to the needs of major government branches, 
                    private firms, and trucking companies worldwide.
                </p>
            </div>
        </section>
        <svg height="2" viewBox="0 0 60% 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line opacity="0.3" y1="1" x2="546" y2="1" stroke="#454444" strokeWidth="2"/>
        </svg>
        <motion.section ref={visionRef} initial={{opacity:0.05,scale:0.99}} animate={visionInView?{opacity:1,scale:1}:{opacity:0.05,scale:0.99}} transition={{duration:0.3,ease:'easeInOut'}} className="abt-sc">
            <div className='abt-h color2'>
                <h2>OUR VISION</h2>
            </div>
            <div className='our-vsn-dv'>
                <p>
                We are committed to transforming the planet through eco-friendly technologies for air, land, and water.
                Our vision is to become a top 10 leader in our sector across the Indian subcontinent and the Middle East, setting a benchmark in sustainable technology.
                We strive to source and deliver innovative, energy-efficient products from around the world, driving impactful change and advancing a cleaner, greener future.
                </p>
                <div className='our-vsn-im'>
                    <motion.img initial={imginit} animate={visionInView?imgfnl:imginit} transition={imgtransition} src="/static/images/vision.svg" alt="vision"/>
                </div>
            </div>
        </motion.section>
        <svg height="2" viewBox="0 0 60% 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line opacity="0.3" y1="1" x2="546" y2="1" stroke="#454444" strokeWidth="2"/>
        </svg>
        <motion.section ref={teamRef} initial={{opacity:0.05,scale:0.99}} animate={teamInView?{opacity:1,scale:1}:{opacity:0.05,scale:0.99}} transition={{duration:0.3,ease:'easeInOut'}} className="abt-sc">
            <div className='abt-h color1'>
                <h2>CORE TEAM</h2>
            </div>
            <div className="avt-tm">
                {Team.map((member)=>{
                    return (
                        <motion.div initial={imginit} animate={teamInView?imgfnl:imginit} transition={imgtransition} className='avt-tm-crd'>
                            <h5>{member.Name}</h5>
                            <span>{member.Position}</span>
                        </motion.div>
                    )
                })}
            </div>
        </motion.section>
        <ImageText title="A Greener & Cleaner Future" imgName={'trading'}/>
    </motion.div>
  )
}

export default About
