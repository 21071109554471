import React, { useEffect, useState, useMemo } from 'react';
import './Header.css';
import {  useLocation, useNavigate } from 'react-router-dom';
import Sitemap from '../../utils/Sitemap';
import BurgerMenu from './BurgerMenu';
import { AnimatePresence,motion } from 'framer-motion';
import LightDark from './LightDark';
 
const Header = () => {
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 880);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const memoizedSitemap = useMemo(() => {
    return <Sitemap isNav={true} />;
  }, []);

  return (
    <div className='header-main'>
      <div className="header-logo" onClick={() => navigate('/')}>
         
          {!isMobile && (
            <>
             <AnimatePresence>
            {location.pathname !=='/our-company/about' && (
              <motion.img initial={{opacity:0,y:10}} animate={{opacity:1,y:0}} exit={{opacity:0,y:10}} transition={{duration:0.3,ease:'easeInOut'}} id="logo-main" src="/static/images/logo-main.svg" alt="logo" />
            )}
            </AnimatePresence>
            </>
          )}
          <AnimatePresence>
          {location.pathname !=='/our-company/about' && (
            <motion.img initial={{opacity:0,y:10}} animate={{opacity:1,y:0}} exit={{opacity:0,y:10}} transition={{duration:0.3,ease:'easeInOut'}} src="/static/images/logo-text.svg" alt="Core Green Trading Company LLC" />
          )}
          </AnimatePresence>
      </div>
      {!isMobile &&(
        <div className="navbar-main">
          {memoizedSitemap}
          {!isMobile && (
            <LightDark/>
          )}
        </div>
      ) }
      {isMobile && (
        <div>
          <BurgerMenu/>
        </div>
      )}      
    </div>
  );
}

export default Header;