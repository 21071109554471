import React from 'react';
import { Helmet } from 'react-helmet-async';

/**
 * 
 * @param {string} title title of the page
 * @param {string} description small description of the page
 * @param {string} name name of the company
 * @param {string} type type of page article,blog etc
 * @param {string} image link for the image if any
 * @param {boolean} hasImage if the link should have a image or not
 * @returns 
 */

const SEO = ({title, description, name, type,hasImage,image}) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name='description' content={description} />

            <meta property="og:type" content={type} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            {hasImage && (
                <meta property="og:image" content={image}/>
            )}
        
            <meta name="twitter:creator" content={name} />
            <meta name="twitter:card" content={type} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            {hasImage && (
                <meta property="twitter:image" content={image}/>
            )}
        </Helmet>
    )
}

export default SEO;
