import { useState } from "react"
import {AnimatePresence, motion} from 'framer-motion'
import { Link } from "react-router-dom"
import sitemap from '../data/Sitemap.json'
/**
 * 
 * @param {boolean} isNav true/false sub items are hidden when set to false.
 * @param {boolean} isBurger true/false items with subitems are given a arrow
 * @returns 
 */

//function that returns the sitemap as a ul
const Sitemap = (props)=>{
    const [showsubName, setShowsubName] = useState('')
    const navlist = sitemap.map(navitem => {
        return(
            <SitemapSubitem key={`${navitem.title}-nav-item-main`} setClicked={props.setClicked} showsubName={showsubName} setShowsubName={setShowsubName} navitem={navitem} isNav={props.isNav} isBurger={props.isBurger}/>
        )
    })

    return(
        <ul >
            {navlist}
        </ul>
    )
}

const SitemapSubitem = (props) =>{

    const handleClick = ()=>{
        if(props.navitem.items.length===0){
            if(props.isBurger){
                props.setClicked(false)
            }
        }
    }

    const handleSubClick = ()=>{
        if(props.isBurger){
            props.setClicked(false)
        }
    }
    // {props.navitem.items.length===0?:''}
    return(
        <motion.li onClick={()=>{props.showsubName===props.navitem.title?props.setShowsubName(''):props.setShowsubName(props.navitem.title)}} key={`${props.navitem.title}-nav-item`}>
                    {props.navitem.items.length===0 && (
                        <Link to={props.navitem.title.toLowerCase().replaceAll(' ','-')==="home"?'/':`${props.navitem.title.toLowerCase().replaceAll(' ','-')}`}>
                            <motion.span onClick={handleClick} style={props.isBurger?{display:'flex',gap:'30px',alignItems:'center',justifyContent:'flex-start'}:{}} whileHover={{opacity:0.5}} transition={{ease:'easeInOut',duration:0.2}}>
                                {props.navitem.title}{props.navitem.items.length!==0&&props.isBurger?<img src="/static/images/more-icon.svg" alt="view more" />:''}
                            </motion.span>
                        </Link>
                    )}
                    {props.navitem.items.length!==0 && (
                        <motion.span onClick={handleClick} style={props.isBurger?{display:'flex',gap:'30px',alignItems:'center',justifyContent:'flex-start'}:{}} whileHover={{opacity:0.5}} transition={{ease:'easeInOut',duration:0.2}}>
                            {props.navitem.title}{props.navitem.items.length!==0&&props.isBurger?<img src="/static/images/more-icon.svg" alt="view more" />:''}
                        </motion.span>
                    )}
                    
                
                <AnimatePresence>
                    {props.navitem.items.length!==0 && props.showsubName === props.navitem.title && (
                        <motion.div initial={{opacity:0,skewY:1,scaleY:0.9,y:-20}} animate={{opacity:1,skewY:0,scaleY:1,y:0}} exit={{opacity:0,skewY:1,scaleY:0.9,y:-20}} transition={{duration:0.2}}>
                            <ul>
                                {props.navitem.items.map((navsubitem,index) =>
                                    <Link key={`${props.navitem.title}-subitem-${navsubitem}-${index}`} to={`${props.navitem.title.toLowerCase().replaceAll(' ','-')}/${navsubitem.toLowerCase().replaceAll(' ','-')==='all-products'?'':navsubitem.toLowerCase().replaceAll(' ','-')}`}>
                                        <motion.li onClick={handleSubClick} tabIndex={0} whileHover={{scale:1.04,opacity:0.4}} transition={{duration:0.1}}>
                                            <span>
                                                {navsubitem}
                                            </span>
                                        </motion.li>
                                    </Link>
                                )}
                            </ul>
                        </motion.div>
                    )}
                </AnimatePresence>
            </motion.li>

    )
}

export default Sitemap

