import React, { useEffect, useState } from 'react'
import './BurgerMenu.css'
import {AnimatePresence, motion} from 'framer-motion'
import Sitemap from '../../utils/Sitemap';
import LightDark from './LightDark';

const BurgerMenu = ()=> {
    const [clicked, setClicked] = useState(false);
    const animationDuration = 0.4;
    const exitAnimation = {opacity:0,x:500,skewX:4}; 

    //func waits for the animation to be over before setting the overflow value
    useEffect(() => {
        if (clicked) {
            document.body.style.overflow = 'hidden';
            document.body.style.position = 'fixed';
            document.body.style.width = '100%';
        } else {
            const timer = setTimeout(() => {
                document.body.style.overflow = '';
                document.body.style.position = '';
                document.body.style.width = '';
            }, animationDuration * 1000);

            return () => clearTimeout(timer);
        }
    }, [clicked, animationDuration]);

    const handleMenuToggle = () => {
        setClicked(!clicked)
      };

    
    return (
        <div className='burger-menu-div'>
            
                <AnimatePresence>
                {clicked && (
                    <>
                        <div className="bgr-overlay"/>
                        <motion.div 
                            id='bgr-menu-main' 
                            initial={exitAnimation} 
                            animate={{x:0,opacity:1,skewX:0}} 
                            exit={exitAnimation} 
                            transition={{ease:'circInOut',duration:animationDuration}} 
                            className='burger-menu-main'>
                            <div className='bgr-menu-nav'>
                                <Sitemap isNav={true} isBurger={true} setClicked={setClicked}/>    
                            </div>
                            <div className='l-d-dv'>
                                    <LightDark/>
                            </div>
                        </motion.div>
                    </>
                    
                )}
                </AnimatePresence>
                <button className={clicked?"menu opened":"menu"} onClick={handleMenuToggle}>
                    <svg width="50" height="50" viewBox="0 0 100 100">
                        <path className="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
                        <path className="line line2" d="M 20,50 H 80" />
                        <path className="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
                    </svg>
                </button>
        </div>
  )
}

export default BurgerMenu