import React from 'react'
import './ErrorPage.css'
import { Link } from 'react-router-dom'
import SEO from '../../components/SEO'

const ErrorPage = () => {
  return (
    <div className='err-pg-main'>
      <SEO
        title="404"
        description="Page not Found"
      />
        <img src="/static/images/lost-logo.svg" alt="core green logo" />
      <div className="err-pg-cont">
        <div className="err-txt">
            <h1>SOMETHING WENT WRONG</h1>
            <h4>404 PAGE NOT FOUND</h4> 
        </div>
            
      </div>
      <Link to="/">
        <span className='to-home'>HOME</span>
      </Link>
    </div>
  )
}

export default ErrorPage
