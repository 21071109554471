import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Router from './components/Router';
import { PageTransitionProvider } from './components/pageTransitionContext';
import { HelmetProvider } from 'react-helmet-async';


function App() {
  return (
    <HelmetProvider>
      <PageTransitionProvider>
        <BrowserRouter>
          <Router/> 
        </BrowserRouter>
      </PageTransitionProvider>
    </HelmetProvider>
  );
}

export default App;
