import { AnimatePresence, motion } from "framer-motion";
import React, { useState,useEffect } from "react";
import './LightDark.css'

const LightDark = (props) => {
    const [isDark, setIsDark] = useState(false);

    const getCurrentColorScheme = () => {
        const backgroundColor = getComputedStyle(document.documentElement)
          .getPropertyValue('--main-bg-clr')
          .trim();
        return backgroundColor === '#1b1b1b';
      };

      useEffect(() => {
        setIsDark(getCurrentColorScheme());
// eslint-disable-next-line
      },[]);
  const applyTheme = (darkmode) => {
    setIsDark(!isDark);
    document.documentElement.style.setProperty(
        "color-scheme",
        isDark ? "light" : "dark"
    );
    const root = document.documentElement;
    if (darkmode) {
        root.style.setProperty("--primary-clr-1", "#419661");
        root.style.setProperty("--primary-clr-2", "#527CB8");
        root.style.setProperty("--main-bg-clr", "#1b1b1b");
  
        root.style.setProperty("--home-grd-clr", "#242B26");
        root.style.setProperty("--footer-clr", "rgb(46, 82, 60)");
  
        root.style.setProperty("--card-clr", "#282828");
        root.style.setProperty("--card-sub-clr-1", "#333333");
        root.style.setProperty("--card-sub-clr-2", "#383838");
        root.style.setProperty("--card-sub-clr-3", "#1b1b1b");
  
        root.style.setProperty("--svg-clr-1", "#65a36e");
        root.style.setProperty("--svg-clr-2", "#287c47");
        root.style.setProperty("--svg-bg", "#242924");
  
        root.style.setProperty("--font-clr", "#D6D6D6");
        root.style.setProperty("--font-clr-bld", "#FFFFFF");
        root.style.setProperty("--font-clr-mn", "white");
  
        root.style.setProperty("--misc-clr", "#ffffff");
        root.style.setProperty("--misc-clr-1", "white");
        root.style.setProperty("--misc-clr-2", "#F6F6F6");
      } else {
        root.style.setProperty("--primary-clr-1", "#419661");
        root.style.setProperty("--primary-clr-2", "#527CB8");
        root.style.setProperty("--main-bg-clr", "#ffffff");
  
        root.style.setProperty("--home-grd-clr", "#DFF2E6");
        root.style.setProperty("--footer-clr", "#419661");
  
        root.style.setProperty("--card-clr", "#eeeeee");
        root.style.setProperty("--card-sub-clr-1", "#f5f5f5");
        root.style.setProperty("--card-sub-clr-2", "#f9f7f7");
        root.style.setProperty("--card-sub-clr-3", "#dddddd");
  
        root.style.setProperty("--svg-clr-1", "#B2C8BA");
        root.style.setProperty("--svg-clr-2", "#6B8A7A");
        root.style.setProperty("--svg-bg", "#EBF3E8");
  
        root.style.setProperty("--font-clr", "#454444");
        root.style.setProperty("--font-clr-bld", "#222222");
        root.style.setProperty("--font-clr-mn", "black");
  
        root.style.setProperty("--misc-clr", "#35453B");
        root.style.setProperty("--misc-clr-1", "white");
        root.style.setProperty("--misc-clr-2", "#F6F6F6");
      }
  };
  const toggleTheme = () => {
    setIsDark((prev) => {
      const newTheme = !prev;
      applyTheme(newTheme);
      return newTheme;
    });
  };

  return (
    <AnimatePresence mode="wait">
    <motion.button whileHover={{opacity:0.6,transition:{duration:0.2}}} onClick={toggleTheme} className="l-d-btn">    
        {isDark && (
            <motion.img initial={{opacity:0,rotate:120}} animate={{opacity:1,rotate:0}} exit={{opacity:0,rotate:120}} transition={{rotate:{duration:0.7,ease:'linear'},duration:0.4,ease:'easeInOut'}} src="/static/images/lightmode.svg" alt="theme toggle" />
        )}
        {!isDark && (
            <motion.img initial={{opacity:0,rotate:-120}} animate={{opacity:1,rotate:0}} exit={{opacity:0,rotate:-120}} transition={{rotate:{duration:0.7,ease:'linear'},duration:0.4,ease:'easeInOut'}} src="/static/images/darkmode.svg" alt="theme toggle" />
        )}         
    </motion.button >
    </AnimatePresence>
  );
};


export default LightDark;
