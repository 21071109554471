import React from 'react'
import './ImageText.css'
/**
 * 
 * @param {string} title title to be displayed
 * @param {string} imgName name of the image that matches the source
 * @returns 
 */

const ImageText = (props) => {
  return (
    <div className='img-txt-main'>
        <h3>{props.title}</h3>
        <div className='img-txt-overlay'></div>
        <img src={`/static/images/${props.imgName}.jpg`} alt={`${props.imgName}`} />
    </div>
  )
}

export default ImageText