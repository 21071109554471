import React from 'react'
import './Loading.css'

export default function Loading(props) {
  return (
    <div className='ld-mn' style={props.height?{height:'100%'}:{}}>
      <svg className='ld-logo' viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M135.23 124.468C127.155 124.468 120.256 124.016 114.531 123.112C108.807 122.107 104.106 120.348 100.426 117.835C96.8484 115.222 94.1908 111.655 92.4531 107.132C90.8177 102.509 90 96.5801 90 89.3442C90 81.5054 91.0732 75.2242 93.2197 70.5008C95.4685 65.6769 98.586 62.059 102.572 59.6471C106.661 57.2351 111.516 55.6271 117.138 54.8232C122.76 54.0192 128.944 53.6172 135.69 53.6172C143.152 53.6172 149.744 53.7679 155.469 54.0694C161.193 54.2704 165.434 54.6724 168.194 55.2754C171.056 55.7779 173.101 56.5819 174.327 57.6874C175.554 58.6923 176.32 59.8481 176.627 61.1545C177.036 62.461 177.24 63.7675 177.24 65.074V70.9531C176.627 70.1491 175.145 69.4959 172.794 68.9934C170.443 68.4909 167.683 68.0889 164.514 67.7874C161.448 67.3854 158.279 67.0839 155.009 66.8829C151.738 66.6819 148.722 66.5814 145.962 66.5814C143.305 66.4809 141.261 66.4307 139.83 66.4307C133.901 66.4307 129.199 67.0337 125.724 68.2396C122.249 69.3451 119.693 71.5561 118.058 74.8725C116.525 78.0884 115.758 82.9123 115.758 89.3442C115.758 95.3741 116.474 99.997 117.905 103.213C119.438 106.328 121.738 108.489 124.804 109.695C127.871 110.901 131.857 111.504 136.763 111.504C141.567 111.504 145.298 111.152 147.956 110.449C150.715 109.645 152.658 108.288 153.782 106.379C154.906 104.469 155.52 101.957 155.622 98.8413C155.622 97.9368 155.622 97.1831 155.622 96.5801C155.622 95.8766 155.622 95.3238 155.622 94.9218H129.557C129.557 91.6054 129.761 89.0427 130.17 87.2338C130.579 85.3243 131.55 83.9676 133.083 83.1636C134.719 82.3596 137.325 81.9576 140.903 81.9576H180L179.847 98.5398C179.847 103.263 179.233 107.283 178.007 110.6C176.882 113.916 174.685 116.579 171.414 118.589C168.245 120.599 163.697 122.107 157.768 123.112C151.942 124.016 144.429 124.468 135.23 124.468Z" fill="#419661"/>
      <path d="M0 89.0427C0 82.0079 0.802832 76.2292 2.40849 71.7068C4.01416 67.0839 6.72372 63.466 10.5372 60.853C14.3506 58.2401 19.4185 56.3809 25.7408 55.2754C32.1634 54.1699 40.0914 53.6172 49.5247 53.6172C53.5388 53.6172 57.3021 53.7679 60.8145 54.0694C64.4272 54.2704 68.1905 54.6724 72.1043 55.2754C76.3192 55.7779 79.0789 56.7829 80.3835 58.2903C81.6881 59.7978 82.3404 61.858 82.3404 64.471V72.1591C79.4302 71.0536 76.0181 70.1491 72.1043 69.4456C68.2909 68.7421 64.4774 68.2396 60.664 67.9381C56.8505 67.5362 53.4887 67.3352 50.5784 67.3352C44.6575 67.3352 39.7903 67.8376 35.9769 68.8426C32.2638 69.8476 29.5542 71.9078 27.8482 75.0233C26.1422 78.1387 25.2892 82.8621 25.2892 89.1935C25.2892 95.2233 26.1422 99.796 27.8482 102.911C29.5542 106.027 32.4143 108.137 36.4285 109.243C40.4426 110.348 45.7614 110.901 52.3848 110.901C55.1947 110.901 58.1551 110.75 61.2661 110.449C64.4774 110.047 67.7891 109.544 71.2011 108.941C74.7135 108.338 78.4266 107.585 82.3404 106.68V113.765C82.3404 116.177 81.6379 118.187 80.233 119.795C78.828 121.303 75.9679 122.358 71.6527 122.961C67.8393 123.564 64.0258 123.966 60.2124 124.167C56.4993 124.368 52.6357 124.468 48.6215 124.468C39.0879 124.468 31.1599 123.966 24.8376 122.961C18.6157 121.855 13.6481 119.996 9.93504 117.383C6.3223 114.77 3.76327 111.203 2.25796 106.68C0.752655 102.057 0 96.1781 0 89.0427Z" fill="#527CB8"/>
      </svg>

      <div className='loader'></div>
    </div>
  )
}
